import Axios from 'axios';

export let SaveDraftCancelToken = Axios.CancelToken.source();

// Cancels save draft requests. Linked to saveUploadMetadata() and saveRevisionDraft()
export const cancelSaveDraftRequests = () => {
  SaveDraftCancelToken.cancel();
  // CancelToken should be re-created because it's already used. The token's state is "Cancel" always after the cancel() called
  SaveDraftCancelToken = Axios.CancelToken.source();
};

export class ApiService {
  async fetchCaseDetails(caseId) {
    return Axios.get(`/apiV2/casedetails/${caseId}`);
  }

  /**
   *
   * @param caseId {String}
   * @param data {{
   *   smile_design_target_date?: String,
   * }}
   * @return {Promise<void>}
   */
  async updateCase(caseId, data) {
    await Axios.put(`/apiv3/case/${caseId}`, data);
  }

  async tpsEnableWasm(case_id) {
    await Axios.post(`/apiv3/tps/enable_wasm/${case_id}`);
  }

  async saveRevisionDraft(caseId, comment) {
    const fdata = new FormData();
    fdata.append('status_comment', comment);
    await Axios.post(`/apiV2/caseaction/${caseId}/save_revision_draft`, fdata, {
      cancelToken: SaveDraftCancelToken.token,
    });
  }

  async cancelRevisionDraft(caseId) {
    await Axios.post(`/apiV2/caseaction/${caseId}/cancel_revision_draft`);
  }

  async resetRevisionDraft(caseId) {
    await Axios.post(`/apiV2/caseaction/${caseId}/reset_revision_draft`);
  }

  isCancel = (err) => {
    return Axios.isCancel(err);
  };
}

export const ApiServiceInstance = new ApiService();
