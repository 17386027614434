import Axios from 'axios';

import { wasmLoadMetadata, getMandibleShiftValues } from './wasm_controller/wasm_controller';
import FormData from 'form-data';
import { PROCESSOR, WASM_ACTION } from './wasm_controller/wasm_controller_const';
import { downloadFileWithSinglePart } from './filedownloader';
import { SaveDraftCancelToken } from '../../common/api_service';

var deepDiffMapper = (function () {
  return {
    VALUE_CREATED: 'created',
    VALUE_UPDATED: 'updated',
    VALUE_DELETED: 'deleted',
    VALUE_UNCHANGED: '---',
    map: function (obj1, obj2) {
      if (this.isFunction(obj1) || this.isFunction(obj2)) {
        throw 'Invalid argument. Function given, object expected.';
      }
      if (this.isValue(obj1) || this.isValue(obj2)) {
        let returnObj = {
          type: this.compareValues(obj1, obj2),
          original: obj1,
          updated: obj2,
        };
        if (returnObj.type != this.VALUE_UNCHANGED) {
          return returnObj;
        }
        return undefined;
      }

      var diff = {};
      let foundKeys = {};
      for (var key in obj1) {
        if (this.isFunction(obj1[key])) {
          continue;
        }

        var value2 = undefined;
        if (obj2[key] !== undefined) {
          value2 = obj2[key];
        }

        let mapValue = this.map(obj1[key], value2);
        foundKeys[key] = true;
        if (mapValue) {
          diff[key] = mapValue;
        }
      }
      for (var key in obj2) {
        if (this.isFunction(obj2[key]) || foundKeys[key] !== undefined) {
          continue;
        }

        let mapValue = this.map(undefined, obj2[key]);
        if (mapValue) {
          diff[key] = mapValue;
        }
      }

      //2020-06-13: object length code copied from https://stackoverflow.com/a/13190981/2336212
      if (Object.keys(diff).length > 0) {
        return diff;
      }
      return undefined;
    },
    compareValues: function (value1, value2) {
      if (value1 === value2) {
        return this.VALUE_UNCHANGED;
      }
      if (typeof value1 === 'number' && typeof value2 === 'number' && value1.toFixed(2) === value2.toFixed(2)) {
        return this.VALUE_UNCHANGED;
      }
      if (this.isDate(value1) && this.isDate(value2) && value1.getTime() === value2.getTime()) {
        return this.VALUE_UNCHANGED;
      }
      if (value1 === undefined) {
        return this.VALUE_CREATED;
      }
      if (value2 === undefined) {
        return this.VALUE_DELETED;
      }
      return this.VALUE_UPDATED;
    },
    isFunction: function (x) {
      return Object.prototype.toString.call(x) === '[object Function]';
    },
    isArray: function (x) {
      return Object.prototype.toString.call(x) === '[object Array]';
    },
    isDate: function (x) {
      return Object.prototype.toString.call(x) === '[object Date]';
    },
    isObject: function (x) {
      return Object.prototype.toString.call(x) === '[object Object]';
    },
    isValue: function (x) {
      return !this.isObject(x) && !this.isArray(x);
    },
  };
})();

function formatTreatmentPlanName(treatment_plan_name) {
  return treatment_plan_name.replace('smiledesign', 'Smile Design ').replace('-dr.edit', ' - Revision').replace('-ifs', ' (IFS)');
}

function formatTreatmentPlanNameToWasm(treatment_plan_name) {
  return treatment_plan_name.toLowerCase().replace('smile design ', 'smiledesign').replace(' - revision', '-dr.edit').replace(' (ifs)', '-ifs');
}

function getSetupNumber(name) {
  const re = /^smiledesign([0-9]+)/;
  const match = name.match(re);

  return Number(match?.[1] || 0);
}

function isValidTreatmentName(name, is_locked, max_setup_number) {
  const re1 = /^smiledesign([0-9]+)(-ifs)?$/;
  const re2 = /^smiledesign([0-9]+)(-dr\.edit)(-ifs)?$/;
  const is_valid_locked_plan = is_locked && name.match(re1);
  const is_valid_dr_plan = name.match(re2);
  const setup_number = getSetupNumber(name);

  const is_valid_format = is_valid_locked_plan || is_valid_dr_plan;
  const is_valid_setup_number = setup_number <= max_setup_number;

  return Boolean(is_valid_format && is_valid_setup_number);
}

function isIFSPlan(tr) {
  return /ifs/i.test(tr.name);
}

function isRevisionIFSPlan(tr) {
  return /-dr\.edit-ifs/i.test(tr.rawName);
}

function isNotIFSPlan(tr) {
  return !isIFSPlan(tr);
}

/**
 * Checks if treatment plan is edited by a doctor
 * @param tr {String}
 * @return {boolean}
 */
function isDrEditedPlan(tr) {
  return tr.toLowerCase().includes('-dr.edit');
}

function sortTreatmentPlanList(plan) {
  const sortBySetupNumberDesc = (a, b) => {
    const setupNumberA = getSetupNumber(a.rawName);
    const setupNumberB = getSetupNumber(b.rawName);
    return setupNumberB - setupNumberA;
  };
  const sortByIFSDesc = (a, b) => {
    const isAinIFS = isIFSPlan(a);
    const isBinIFS = isIFSPlan(b);
    return isBinIFS - isAinIFS;
  };
  const sortByNameDesc = (a, b) => b.name.localeCompare(a.name);

  const sorted = plan.slice();
  sorted.sort((a, b) => sortBySetupNumberDesc(a, b) || sortByIFSDesc(a, b) || sortByNameDesc(a, b));

  return sorted;
}

async function readFile2Buffer(downloadInfo, tpName, updatePercentage) {
  try {
    const metadata = wasmLoadMetadata();

    let tpNameInMetadata = false;
    if (metadata?.TreatmentPlanStorage?.TreatmentPlans?.length) {
      const treatmentPlansNames = metadata.TreatmentPlanStorage.TreatmentPlans.map((tr) => tr.Name);
      tpNameInMetadata = treatmentPlansNames.includes(tpName);
    }

    if (!metadata || (metadata.status !== 404 && tpNameInMetadata)) {
      console.log('There is a case already loaded. Skipping opening process...');
      return;
    }
    const buffer = await downloadFileWithSinglePart(downloadInfo);
    const path = `/Cases/case.zip`;
    window.FS.writeFile(path, new Uint8Array(buffer));
    OpenCaseProcessor(path, tpName);
  } catch (e) {
    console.error('There was an error when downloading/loading the case on the viewer: ', e);
  }
}

function OpenCaseProcessor(path, name) {
  const controller = new window.Module.SegmentorController();
  console.log({
    Processor: PROCESSOR.OpenCase,
    Action: WASM_ACTION.OpenCase.Open,
    CaseZipFile: path,
    OpenTreatmentPlanName: name,
  });
  const request = JSON.stringify({
    Processor: PROCESSOR.OpenCase,
    Action: WASM_ACTION.OpenCase.Open,
    CaseZipFile: path,
    OpenTreatmentPlanName: name,
  });
  controller.execute(request);
}

const makeForm = (file, config) => {
  const form = new FormData();

  Object.keys(config.fields).forEach((k) => {
    const v = config.fields[k];
    form.append(k, v);
  });

  form.append('file', file);

  return form;
};

/**
 * @deprecated This function is deprecated and may be removed in future releases.
 *  Use `uploadMetadata` instead.
 */
const uploadToS3 = (file, config) =>
  new Promise((resolve, reject) => {
    const fd = makeForm(file, config);
    const xhr = new XMLHttpRequest();

    xhr.onerror = reject;

    xhr.open('POST', config.url, true);
    xhr.send(fd);
  });

/**
 * Saves metadata by uploading it to a specified endpoint and handling success and failure callbacks.
 *
 * @param {string} case_id - The identifier for the associated case.
 * @param {string} eslingual_id - The eslingual identifier for the associated.
 * @param {Function} onSuccess - The callback function to be executed on successful metadata upload.
 *   @param {Object} response - The response object containing details about the upload.
 * @param {Function} onFail - The callback function to be executed on failed metadata upload.
 *   @param {Error} error - The error object containing details about the failed upload.
 *
 * @returns {Promise<void>} This function does not return a value directly, but invokes the specified callbacks.
 */
function saveUploadMetadata(case_id, eslingual_id, onSuccess, onFail) {
  const metadata = wasmLoadMetadata();
  console.log('=========================== DEBUG: METADATA ===========================');
  console.log(metadata);
  const file = new Blob([JSON.stringify(metadata, null, 2)], { type: 'application/json' });

  return uploadMetadata(case_id, eslingual_id, file).then(onSuccess).catch(onFail);
}

/**
 * Uploads metadata file to a specified endpoint using Axios.
 *
 * @param {string} case_id - The identifier for the associated case.
 * @param {string} eslingual_id - The eslingual identifier.
 * @param {File} metadata - The metadata file to be uploaded.
 *
 * @returns {Promise} A Promise that resolves with the Axios response on successful upload or rejects on error.
 *   @resolve {Object} The Axios response object containing details about the upload.
 *   @reject {Error} An error object containing details about the failed metadata file upload.
 */
function uploadMetadata(case_id, eslingual_id, metadata) {
  const formData = new FormData();
  formData.append('file', metadata);
  const saveEndpoint = process.env.REACT_APP_CASE_ZIPPER_URL + '/save';
  return Axios.post(saveEndpoint, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    params: {
      case_id: case_id,
      eslingual_id: eslingual_id,
    },
    cancelToken: SaveDraftCancelToken.token,
  });
}

function getNewTreatmentPlanName(curr_name) {
  const formatted = curr_name.replace(/ /g, '').toLowerCase();
  return `${formatted}-dr.edit`;
}

function addOnCloseListener() {
  window.onbeforeunload = function (e) {
    var e = e || window.event;
    if (e) e.returnValue = '';
    return '';
  };
}

function removeOnCloseListener() {
  window.onbeforeunload = null;
}

/**
 * Checks if the mandible has any shift values.
 * @returns {boolean} True if any of the shift values (Angle, ShiftX, ShiftY) is not equal to 0, otherwise false.
 */
function doesHaveMandibleShift() {
  const { Angle, ShiftX, ShiftY } = getMandibleShiftValues();
  return [Angle, ShiftX, ShiftY].some((v) => v !== 0);
}

export {
  addOnCloseListener,
  deepDiffMapper,
  doesHaveMandibleShift,
  formatTreatmentPlanName,
  formatTreatmentPlanNameToWasm,
  getNewTreatmentPlanName,
  getSetupNumber,
  isDrEditedPlan,
  isIFSPlan,
  isRevisionIFSPlan,
  isNotIFSPlan,
  isValidTreatmentName,
  readFile2Buffer,
  removeOnCloseListener,
  saveUploadMetadata,
  sortTreatmentPlanList,
};
