import './wasm_viewer.scss';

import Axios from 'axios';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import cn from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Hints, Steps } from 'intro.js-react';

import NotFound from '../../doctor/404/not_found';

import { withUserPermission } from '../../context/user_permission';
import { convertAliasToCaseId } from '../../common/case/case_id';
import { getBusinessRoleList } from '../../common/helpers';
import WasmToolBar from './toolbar/wasm_toolbar';

import { SETUP_PREFIX } from '../setup_viewer/components/common/functions';

import { fetchCaseFileData } from '../../redux/actions/ipp/case_details/case_files';
import { fetchProductTour, updateProductTour } from '../../redux/actions/product_tour/product_tour';
import { clearDraftSaves, fetchCaseDetails } from '../../redux/actions/common/common_case_details';
import {
  getCaseDetails,
  getCaseDetailsError,
  getCaseDetailsLoading,
  getCaseStatus,
  isEnrolledProviderEditPhase2,
} from '../../redux/reducers/common/common_case_details';
import { getCaseFileError, getCaseFileList, getCaseFileListLoading } from '../../redux/reducers/ipp/case_details/case_files';
import { getProductTours } from '../../redux/reducers/product_tour/product_tour';
import WasmMain from './wasm_main';
import Logo from '../setup_viewer/components/panels/menu/logo';
import ResizableSidebar from './sidebar/resizable_sidebar';
import WasmShortcuts from './wasm_shortcuts';
import {
  onToggleOption,
  onUpdateHistory,
  resetState,
  autoSaveCaseDraft,
  setActiveTeeth,
  setIsResetEnabled,
  setLoading,
  setMissingTeeth,
  setRevisedTreatmentPlan,
  setReviseOnCaseDetailsSuccess,
  setSelectedPlanError,
  setTMTIncrementDelta,
  setTreatmentPlanList,
  updateEditingMode,
  updateInitialIPR,
  updateInitialMovementTable,
  updateIprTable,
  updateMovementTable,
  updateIprState,
} from '../../redux/actions/wasm_viewer/wasm_viewer';
import {
  getCaseInfoState,
  getEditingModeState,
  getHistoryState,
  getIprTable,
  getMovementTable,
  getTreatmentPlanList,
  resetCurrentTreatmentPlan,
  setWasmBgColor,
  toggleMultiSelection,
  toggleRestrictionNotification,
  wasmClearHistory,
  setWasmActiveTreatmentPlan,
} from './wasm_controller/wasm_controller';
import ResetModal from './modal/reset_modal';
import SaveRevisionModal from './modal/save_revision_modal';
import { getWasmViewerState } from '../../redux/selectors/wasm_viewer';
import WasmMovementTable, { formatMovementData, getActiveTeeth } from './movement_table/wasm_movement_table';
import SaveDraftModal from './modal/save_draft_modal';
import {
  formatTreatmentPlanName,
  formatTreatmentPlanNameToWasm,
  isDrEditedPlan,
  isNotIFSPlan,
  isValidTreatmentName,
  readFile2Buffer,
  sortTreatmentPlanList,
} from './wasm_helper';
import { EXTRA_HINTS, FIRST_HINTS, INCREMENTAL_MOVEMENT_HINTS, SECOND_HINTS, TOUR_STEPS } from './product_tour/constants';
import TourHelpPanel from './product_tour/tour_help_panel';
import TeethUtils from '../../common/teeth_utils';
import { formatIPR } from './ipr/wasm_ipr';
import WasmBottomContainer from './wasm_bottom_container';
import CircleLoader from '../loader/circle_loader';
import FeedbackForm from '../../components/feedback_rating_form_post_smile_design';
import { ROLE } from '../../common/role';
import { CaseStatus, ECaseProcessStatus } from '../../common/case/case_details.constants';
import { AUTO_SAVE_DRAFT_TIMEOUT, ETreatmentPlanStage, TMT_HEIGHT, WASM_BG, WASM_IPR_MODE } from './wasm-constants';
import WasmAnimationProgressBar from './wasm_animation_progress_bar';
import { PROCESSOR, WASM_PROP } from './wasm_controller/wasm_controller_const';
import { isPostApprovalStage, isIFSReadyForRelease } from '../../common/case/case_status';
import { EHasPermissionMode, EUserPermission, userHasPermissions } from '../../common/permission';
import { HideContentIf } from '../../common/hocs/hide-content-if';
import { withIprAndTmtChanges } from './hoc/with-ipr-and-tmt.changes';
import WasmIncMovement from './movement_table/wasm_inc_movement';
import { ToothPopupMenuProcessor } from './wasm_controller/processors/tooth-popup-menu-processor';
import { ErrorHandlerProcessor } from './wasm_controller/processors/error-handler-processor';
import { createNotification, ENotificationType } from '../notifications/signals/notifications';
import { combineFilters } from '../../common/utils/filters';
import { removeDuplicatesFromArray } from '../../common/functions';
import { autoSaveTimeoutSignal } from './signals/autosave';

const MAX_TIME_TO_WAIT_FOR_DOWNLOAD_PROGRESS_IN_MS = 200;

/**
 * Displays the entire Smile Design Viewer
 * @component
 * @alias WasmViewer
 */
class WasmViewer extends Component {
  state = {
    split: false,

    image_records: [],
    history_list: [],
    status: '',

    models: [],
    default_record_states: [],
    scripts: [],
    setup_selections: [],

    percentage: 0,

    reset_modal: false,

    toothPopupMenuState: undefined,
    wasNotFound: false,
    isIncMovementOpen: false,

    tourPhaseCompleted: 0,
    stepsEnabled: false,
    hintsEnabled: false,
    hints: FIRST_HINTS,
    // poppedHints controls hints that should be shown, but are not because the element's not visible
    poppedHints: [],
    // dismissedHints controls hints that the user has dismissed
    dismissedHints: [],
    loadingInitialTreatmentPlan: true,

    tmtHeight: 0,
  };

  componentDidMount() {
    this.loadCaseDetails();
    this.setupOnWasmInitialized();
    this.loadSetupSelection();
    this.props.fetchProductTour('wasm_viewer');
    this.handleTmtDynamicHeight();
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentDidUpdate(prevProps) {
    const finishedFetchingCaseDetails = this.props.case_details && !this.props.case_details_loading && !this.props.case_details_error;
    const finishedFetchingCaseFiles = this.props.case_file_list && !this.props.case_file_list_loading && !this.props.case_file_list_error;
    const caseDetailsChanged = this.props.case_details !== prevProps.case_details || this.props.case_file_list !== prevProps.case_file_list;
    const isRevisingChanged = this.props.isRevising !== prevProps.isRevising;
    const didOpenTMT = this.props.tmt && !prevProps.tmt;
    const didCloseTMT = !this.props.tmt && prevProps.tmt;
    const didChangeAnimating = this.props.isAnimating !== prevProps.isAnimating;
    const stateChanges = {};

    if (finishedFetchingCaseDetails && finishedFetchingCaseFiles && caseDetailsChanged) {
      stateChanges.historyList = this.buildHistoryList();
    }
    if (isRevisingChanged || didOpenTMT || didCloseTMT) {
      stateChanges.hints = this.getHints(false, isRevisingChanged || didOpenTMT, didCloseTMT);
    }
    if (didChangeAnimating) {
      this.handleAnimatingHints(this.props.isAnimating);
    }
    if (Object.keys(stateChanges).length) {
      //Timeout is because of the way the hints are rendered, they need to be rendered after all the animations are done
      setTimeout(() => this.setState(stateChanges), 250);
    }
    if (this.props.tmt !== prevProps.tmt) {
      this.handleTmtDynamicHeight();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  getTmtHeight = (visualViewport) => {
    if (!this.props.tmt) {
      return 0;
    }

    if (visualViewport.width <= 1260) {
      return 183;
    }

    if (visualViewport.width <= 1400) {
      return 201;
    }

    if (visualViewport.width <= 1500) {
      return 219;
    }

    return TMT_HEIGHT;
  };

  handleTmtDynamicHeight = () => {
    const newTmtHeight = this.getTmtHeight(window.visualViewport);
    if (newTmtHeight !== this.state.tmtHeight) {
      this.setState({ tmtHeight: newTmtHeight });
    }
  };

  handleWindowResize = () => {
    this.handleTmtDynamicHeight();
  };

  getCaseId = () => {
    const { case_id } = this.getURLParams();
    return convertAliasToCaseId(case_id);
  };

  onClickLogo = (doctorId) => {
    const { cases, case_id, role } = this.props.case_details;
    const hasAccessToBusinessPortal = getBusinessRoleList().includes(role);
    const latest_case_id = cases[cases.length - 1].case_id;

    if (hasAccessToBusinessPortal) {
      this.props.history.push(`/business/portal/case/${case_id}`);
    } else {
      this.props.history.push(`/portal/${doctorId}/case/${latest_case_id}`);
    }
  };

  onUpdateListener = (processorList) => {
    console.log('========== onUpdateListener ==========');
    for (let i = 0; i < processorList.size(); i++) {
      const processorsName = processorList.get(i);
      console.log(processorsName);
      if (processorsName === PROCESSOR.History) {
        this.updateHistoryState();
        this.restartDelayedSaveDraft();
      } else if (processorsName === PROCESSOR.MovementTable) this.updateMovementTable();
      else if (processorsName === PROCESSOR.IPRTable) this.updateIPR();
      else if (processorsName === PROCESSOR.CaseInfo) {
        this.updateTreatmentPlan();
        this.updateInitialWasmState();
        this.updateResetState();
        //!todo ask AMV team to update movement table when SetActiveStage is called
        this.updateMovementTable(); //workaround for issue https://ibskunkworks.atlassian.net/browse/OB-5758
      } else if (processorsName === PROCESSOR.EditingMode) {
        this.updateEditingModeState();
      } else if (processorsName === PROCESSOR.OpenCase) {
        this.props.onFetchIPRAndTMTChanges();
      } else if (processorsName === PROCESSOR.ToothPopupMenu) {
        this.showToothPopupMenu();
      } else if (processorsName === PROCESSOR.ErrorHandler) {
        this.handleWasmErrors();
      }
    }
    console.log('========== onUpdateListener ==========');
  };

  showToothPopupMenu = () => {
    const state = ToothPopupMenuProcessor.getState();
    this.setState({ toothPopupMenuState: state });
  };

  handleClickToothPopupItem = (action) => {
    const { toothPopupMenuState } = this.state;
    ToothPopupMenuProcessor.handleToothAction(toothPopupMenuState.fdis, action);
    this.setState({ toothPopupMenuState: null });
  };

  handleResetToothPopupMenu = (e) => {
    if (!this.state.toothPopupMenuState) {
      return;
    }
    if (!e.target.className.includes('tooth-popup-menu')) {
      this.setState({ toothPopupMenuState: null });
    }
  };

  restartDelayedSaveDraft = () => {
    clearTimeout(autoSaveTimeoutSignal.value);
    const { isEnrolledPhase2 } = this.props;
    if (isEnrolledPhase2) {
      console.log('Enabling auto save draft');
      autoSaveTimeoutSignal.value = setTimeout(() => {
        const { status, undo, redo } = getHistoryState();
        const isNotFound = status === 404;
        const isUndoAndRedoDisabled = !undo && !redo;
        if (isNotFound || isUndoAndRedoDisabled) {
          return console.log('HistoryProcessor not initialized or undo and redo unavailable');
        }
        this.saveDraft();
      }, AUTO_SAVE_DRAFT_TIMEOUT);
    }
  };

  saveDraft = (isFailingSilently = false) => {
    const { revisionNote } = this.props;
    const { eslingual_id: eslingualId } = this.props.case_details;
    let { case_id: caseId } = this.getURLParams();
    caseId = convertAliasToCaseId(caseId);
    this.props.autoSaveCaseDraft(caseId, eslingualId, revisionNote, { isFailingSilently });
  };

  handleWasmErrors = () => {
    const errorHandlerState = ErrorHandlerProcessor.getState();
    if (!errorHandlerState) {
      return;
    }
    createNotification(errorHandlerState.message, ENotificationType.Warning, 2000);
  };

  setupOnWasmInitialized() {
    window.onWasmInitialized = () => {
      const controller = new window.Module.SegmentorController();
      controller.setUpdateListener(this.onUpdateListener);

      this.loadWasmCase();
      setTimeout(() => setWasmBgColor(WASM_BG), 0);
    };
  }

  updatePercentage = (percentage) => {
    if (percentage > this.state.percentage) {
      this.setState({ percentage });
    }
  };

  /**
   * Simulates a fake percentage update over a specified duration.
   *
   * @function fakePercentageUpdate
   * @param {number} duration - The duration of the update process in milliseconds.
   * @param {number} fakeUntil - The maximum fake percentage value to reach.
   * @returns {void}
   */
  fakePercentageUpdate = (duration, fakeUntil) => {
    const interval = 10; // Update interval in milliseconds
    const totalSteps = duration / interval;
    let currentStep = 0;
    let timerId;

    const update = () => {
      currentStep++;
      const percentage = (currentStep / totalSteps) * fakeUntil;
      this.updatePercentage(Math.min(percentage, fakeUntil));

      if (currentStep < totalSteps) {
        timerId = setTimeout(update, interval);
      }
    };
    update();
  };

  loadWasmCase = async (isRetry = false) => {
    this.props.setSelectedPlanError();
    this.updatePercentage(0);
    this.fakePercentageUpdate(1000, 20);
    const caseId = this.getCaseId();
    const tpName = this.getInitialTreatmentName();

    if (!caseId) {
      this.setState({ wasNotFound: true });
      return;
    }

    this.props.setLoading({ loading: true, error: false, isRetry });
    this.setState({ loadingInitialTreatmentPlan: true });
    try {
      const { data } = await Axios.get(`/apiv3/tps/download?case_id=${caseId}`);
      if (!data.path) {
        throw new Error(`Response to /apiv3/tps/download?case_id=${caseId} does not have response.data.path`);
      }

      let hasDownloadTickHappened = false;
      navigator.serviceWorker.addEventListener('message', async (event) => {
        if (event.data.action !== 'downloadProgress') return;
        if (event.data.e_tag !== data.e_tag) return;
        hasDownloadTickHappened = true;
        const progress = event.data.progress;
        this.updatePercentage(progress);
      });
      setTimeout(async () => {
        if (hasDownloadTickHappened) {
          console.log('There was a download tick in the waiting period, waiting for download to complete...');
          navigator.serviceWorker.addEventListener('message', async (event) => {
            if (event.data.action !== 'downloadComplete') return;
            if (event.data.e_tag !== data.e_tag) return;
            console.log('Download complete for: ', event.data.e_tag);
            readFile2Buffer(data, tpName, this.updatePercentage);
          });
        } else {
          console.log('There were no download ticks in the waiting period, triggering a new download...');
          readFile2Buffer(data, tpName, this.updatePercentage);
        }
      }, MAX_TIME_TO_WAIT_FOR_DOWNLOAD_PROGRESS_IN_MS);
    } catch (e) {
      this.props.setLoading({ loading: false, error: true });
      this.setState({ loadingInitialTreatmentPlan: false });
    }
  };

  updateHistoryState() {
    const { undo, redo } = getHistoryState();
    this.props.updateHistoryState(undo, redo);
  }

  updateResetState() {
    const caseInfoState = getCaseInfoState();
    if (WASM_PROP.CaseInfo.RestorePlanFromParentEnabled in caseInfoState) {
      this.props.setIsResetEnabled(caseInfoState[WASM_PROP.CaseInfo.RestorePlanFromParentEnabled]);
    }
  }

  updateMovementTable() {
    const tmt = getMovementTable();
    const teeth = formatMovementData(tmt.teeth);
    this.props.setActiveTeeth(getActiveTeeth(tmt.teeth));
    this.props.updateMovementTable(teeth);
    this.props.setTMTIncrementDelta(tmt.incrDelta);
  }
  /**
   * Get steps for the current phase
   * @returns {Array<Object>} Array of steps
   */
  getTourSteps() {
    const { tourPhaseCompleted } = this.state;
    const { isEnrolledPhase2 } = this.props;
    if (tourPhaseCompleted === 0 || (tourPhaseCompleted === 1 && isEnrolledPhase2)) {
      return TOUR_STEPS.filter((step) => step.phase === tourPhaseCompleted + 1);
    }
    return [];
  }

  updateInitialWasmState() {
    const isTableEmpty = !this.props.movement_table;
    if (isTableEmpty) {
      const { teeth } = getMovementTable();
      const ipr = getIprTable();
      if (teeth) {
        const { isEnrolledPhase2 } = this.props;
        const format_data = formatMovementData(teeth);
        const format_ipr = formatIPR(ipr);
        toggleMultiSelection(isEnrolledPhase2);
        toggleRestrictionNotification(isEnrolledPhase2);
        this.updateMissingTeeth(teeth);
        this.props.updateInitialIPR(format_ipr);
        this.props.updateIprTable(format_ipr);
        this.props.updateInitialMovementTable(format_data);
        this.props.updateMovementTable(format_data);
        this.props.updateIprState(WASM_IPR_MODE.initial);
        this.props.setLoading({ loading: false });
        const wasmTour = this.props.product_tours.find((tour) => tour.tour_slug === 'wasm_viewer');
        if (wasmTour) {
          const { tour_completed, tour_phase } = wasmTour;
          this.setState({ tourPhaseCompleted: tour_phase });
          if (!tour_completed || tour_phase < 2) {
            this.setState({ stepsEnabled: true, hintsEnabled: true, hints: this.getHints(true, true) });
            window.$('[data-toggle="tooltip"]').tooltip();
          }
        } else {
          this.setState({ stepsEnabled: true, hintsEnabled: true, hints: this.getHints() });
          window.$('[data-toggle="tooltip"]').tooltip();
        }
      }
    }
  }

  updateEditingModeState() {
    const editingModeState = getEditingModeState();
    if (!editingModeState) {
      return;
    }
  }

  handleEditingModeChange = (e) => {
    const editingModeId = e.target.value;
    this.props.updateEditingMode(editingModeId);
  };

  updateIPR() {
    const ipr = getIprTable();
    if (ipr) {
      const ipr_table = formatIPR(ipr);
      this.props.updateIprTable(ipr_table);
    }
  }

  updateMissingTeeth(move_data) {
    if (move_data) {
      const teeth_set = TeethUtils.teethSetInPalmerBySection();
      const all_teeth = [...teeth_set.upper, ...teeth_set.lower];
      const teeth_ids = new Set(move_data.map((data) => data[WASM_PROP.MovementTable.AlphaNumID]));
      const missing_teeth = all_teeth.filter((teeth) => !teeth_ids.has(teeth));
      this.props.setMissingTeeth(missing_teeth);
    }
  }

  getMaxSetupNumber = () => {
    const { case_file_list, case_details } = this.props;
    const caseFile = case_file_list[case_file_list.case_id];
    if (!caseFile.setup_process) {
      return 0;
    }
    const isAbleToSeeUncompleted = case_details.role !== ROLE.Doctor;
    const setupProcess = isAbleToSeeUncompleted ? caseFile.setup_process : caseFile.setup_process.filter((s) => s.process_completed);
    const latestCompletedSetup = setupProcess.at(-1);
    return latestCompletedSetup.index;
  };

  getTreatmentPlanList = () => {
    const { isRevising, caseStatus } = this.props;
    const { history_list: historyList } = this.state;
    const rawTreatmentPlanList = getTreatmentPlanList();
    const maxSetupNumber = this.getMaxSetupNumber();
    const mapTreatmentPlan = (tr, index) => ({
      name: formatTreatmentPlanName(tr[WASM_PROP.CaseInfo.Name]),
      rawName: tr[WASM_PROP.CaseInfo.Name],
      index,
      isLocked: tr[WASM_PROP.CaseInfo.IsLocked],
    });
    const isAwaitingDoctorDecision = caseStatus?.case_status === CaseStatus.DoctorApproveOrReviseSetup;
    const takeValidTreatmentPlan = (tr) => isValidTreatmentName(tr.rawName, tr.isLocked, maxSetupNumber);
    const takeOnlyLockedPlansForViewing = (tr) => isRevising || tr.isLocked;
    const takeDrEditPlansBeforeRevision = (tr) => {
      return isAwaitingDoctorDecision && !isRevising ? !tr.rawName.includes(`smiledesign${maxSetupNumber}-dr.edit`) : true;
    };

    const takeCorrectTreatmentPlans = combineFilters(takeValidTreatmentPlan, takeOnlyLockedPlansForViewing, takeDrEditPlansBeforeRevision);
    const takeNotIFSPlans = combineFilters(takeCorrectTreatmentPlans, isNotIFSPlan);
    const mappedPlans = rawTreatmentPlanList.map(mapTreatmentPlan);
    const treatmentPlans = mappedPlans.filter(takeNotIFSPlans);

    const lastHistoryItemName = formatTreatmentPlanNameToWasm(historyList.at(0)?.title || '');
    const isDrEdited = isDrEditedPlan(lastHistoryItemName);
    const takeIFSPlan = (tr) => (isDrEdited ? tr.rawName === `smiledesign${maxSetupNumber}-dr.edit-ifs` : tr.rawName === `smiledesign${maxSetupNumber}-ifs`);
    const ifsPlan = mappedPlans.find(takeIFSPlan);
    const ifsPlans = ifsPlan ? [ifsPlan] : [];

    const { case_details: caseDetails } = this.props;
    const isDoctor = caseDetails.role === ROLE.Doctor;
    const isDSO = !getBusinessRoleList().includes(this.props.case_details.role);
    const currentCase = caseDetails.cases.find((c) => c.case_id === caseDetails.case_id);
    const isIfsReadyForRelease = isIFSReadyForRelease(currentCase);
    const isIFSVisible = !isDSO && !isDoctor && isIfsReadyForRelease;
    return isIFSVisible ? [...treatmentPlans, ...ifsPlans] : treatmentPlans;
  };

  /**
   *
   * @param treatmentPlanList
   * @return {Number | undefined}
   */
  getInitialTreatmentPlanIndex = (treatmentPlanList) => {
    const { isRevising } = this.props;
    const urlParams = new URLSearchParams(window.location.search);
    const selectedTreatmentName = urlParams.get('selected_treatment_name');
    if ((isRevising || !selectedTreatmentName) && treatmentPlanList.length) {
      return treatmentPlanList.at(0).index;
    }

    if (!selectedTreatmentName) {
      return;
    }

    const selectedTreatmentPlan = treatmentPlanList.find((plan) => plan.name === selectedTreatmentName);
    if (selectedTreatmentPlan) {
      return selectedTreatmentPlan.index;
    }

    if (!selectedTreatmentName.includes('Revision')) {
      const selectedTreatmentNameRevisionFallback = `${selectedTreatmentName} - Revision`;
      const selectedTreatmentPlanRevisionFallback = treatmentPlanList.find((plan) => plan.name === selectedTreatmentNameRevisionFallback);
      if (selectedTreatmentPlanRevisionFallback) {
        return selectedTreatmentPlanRevisionFallback.index;
      }
    }

    if (selectedTreatmentName.includes(' - Revision')) {
      const selectedTreatmentNameNonRevisionFallback = selectedTreatmentName.replace(' - Revision', '');
      const selectedTreatmentPlanNonRevisionFallback = treatmentPlanList.find((plan) => plan.name === selectedTreatmentNameNonRevisionFallback);
      if (selectedTreatmentPlanNonRevisionFallback) {
        return selectedTreatmentPlanNonRevisionFallback.index;
      }
    }
  };

  getInitialTreatmentName = () => {
    const { isRevising } = this.props;
    const urlParams = new URLSearchParams(window.location.search);
    const selectedTreatmentName = urlParams.get('selected_treatment_name');
    if (!selectedTreatmentName || isRevising) {
      return;
    }
    return formatTreatmentPlanNameToWasm(selectedTreatmentName);
  };

  updateTreatmentPlan = () => {
    const { isRevising } = this.props;
    const treatmentPlanList = this.getTreatmentPlanList();
    const sortedPlanList = sortTreatmentPlanList(treatmentPlanList);
    const index = this.getInitialTreatmentPlanIndex(sortedPlanList);

    const isTreatmentPlanInvalid = () => {
      return (!sortedPlanList.length || typeof index !== 'number')
    }

    if (this.props.isLoadCaseRetry) {
      if (isTreatmentPlanInvalid()) this.props.setSelectedPlanError('Treatment Plan Not Found');
      this.props.setLoading({ loading: false, isRetry: false });
      this.setState({ loadingInitialTreatmentPlan: false });
    }


    if (isTreatmentPlanInvalid()) {
      return this.props.setSelectedPlanError('Treatment Plan Not Found');
    }

    if (isRevising) {
      this.props.setRevisedTreatmentPlan(index);
    }
    this.props.setTreatmentPlanList(sortedPlanList, index);
    if (this.state.loadingInitialTreatmentPlan) {
      setWasmActiveTreatmentPlan(index, () => this.setState({ loadingInitialTreatmentPlan: false }));
    }
    this.props.setSelectedPlanError();
  };

  /**
   * Loads case details
   * @function
   * @returns {Promise<void>}
   */
  loadCaseDetails = async () => {
    const caseId = this.getCaseId();

    if (caseId) {
      if (this.props.case_details && this.props.case_file_list) {
        this.buildHistoryList();
      } else {
        try {
          const caseDetails = await this.props.fetchCaseDetails(caseId);
          if (!caseDetails || !caseDetails.cases || !caseDetails.cases.length) {
            throw new Error(this.props.case_details_error);
          }
          this.ensureRevisionContinuation(caseDetails);
          this.props.fetchCaseFileData(caseId);
        } catch (e) {
          console.error('Error fetching case details:', e);
          this.setState({ wasNotFound: true });
        }
      }
    }
  };

  loadSetupSelection = async () => {
    let case_id_array = [];
    if (this.props.case_details && this.props.case_details.cases?.length) {
      this.props.case_details.cases.forEach((c) => {
        case_id_array.push(c.case_id);
      });

      const initParams = { case_id_array: case_id_array.reduce((f, s) => `${f},${s}`) };
      const response = await Axios.request({
        url: '/apiv3/tps/download',
        method: 'GET',
        params: initParams,
      });

      if (response && response.data) {
        this.setState({ setup_selections: response.data.case_with_presigned_url });
      }
    }
  };

  getURLParams(lastestSetup = 0) {
    const { case_id, pane_1, pane_2 } = this.props.match && this.props.match.params;
    const default_pane = lastestSetup === 0 ? 'Records' : lastestSetup;
    return {
      case_id,
      pane_1: pane_1 ? pane_1 : default_pane,
      pane_2: pane_2 ? pane_2 : default_pane,
    };
  }

  /**
   * Builds the list of setup history
   * @function
   * @return {Number} Number of setups this case has
   */
  buildHistoryList() {
    const { case_file_list, case_details } = this.props;

    const case_file = case_file_list[case_file_list.case_id];
    const detail = case_details.cases?.find((detail) => detail.case_id === case_details.case_id);

    if (case_file.setup_process && case_file.setup_process.length > 0 && detail) {
      const history_list = this.buildHistoryListFromCaseDetail(case_file.setup_process);
      const status = this.getSetupStatus(case_file.setup_process, detail);
      this.setState({ history_list: history_list, status: status });
    }
  }

  buildHistoryListFromCaseDetail(setup_process) {
    let setups = [];
    for (let i = setup_process.length - 1; i >= 0; i--) {
      const setup = setup_process[i];
      const setupLog = setup.log.filter((l) => l.status_code !== CaseStatus.ProviderEdit);

      if (this.shouldShowSetup(setup)) {
        if (this.hasRevision(setup_process, i)) {
          setups.push({
            title: `${SETUP_PREFIX}${setup.index} - Revision`,
            ...this.getDoctorComment(setup.setup_status, setup_process[i + 1]),
          });
        }
        setups.push({
          title: `${SETUP_PREFIX}${setup.index}`,
          ...this.getInbraceComment(setupLog),
        });
      }
    }
    return setups;
  }

  /**
   * Ensures that the revision draft editing is continued
   * @param caseDetails {{
   *   case_id: String,
   *   cases: Array<{
   *     case_id: String,
   *     status_code: CaseStatus,
   *     status_message: String,
   *     process_status_code: ECaseProcessStatus,
   *     process_status_text: String,
   *   }>,
   * }}
   * @returns void
   */
  ensureRevisionContinuation = (caseDetails) => {
    const { permissions } = this.props;
    const approveOrRevisePermissions = [EUserPermission.SmileDesignApprove, EUserPermission.SmileDesignRevise];
    const currentCase = caseDetails.cases.find((c) => c.case_id === caseDetails.case_id);
    const isAwaitingDoctorDecision =
      currentCase?.status_code === CaseStatus.DoctorApproveOrReviseSetup || currentCase?.status_code === CaseStatus.StatusDoctorApproval;
    const isRevisionDraftProcess = currentCase?.process_status_code === ECaseProcessStatus.RevisionDraft;
    const isUserPermitted = userHasPermissions(approveOrRevisePermissions, permissions, EHasPermissionMode.All);
    if (isRevisionDraftProcess && isAwaitingDoctorDecision && isUserPermitted) {
      this.props.setReviseOnCaseDetailsSuccess(caseDetails);
    }
  };

  hasRevision(setup_process, i) {
    const isLatestSetup = i + 1 === setup_process.length;
    if (isLatestSetup) {
      const isProviderEditReview = setup_process[i].log?.at(-1)?.status_code === CaseStatus.ProviderEdit;
      return isProviderEditReview;
    }

    const ifsUploadApproved = setup_process[i + 1].log?.[0]?.status_code === CaseStatus.IFSUpload;
    return !ifsUploadApproved;
  }

  shouldShowSetup(setup) {
    const hasSetupLog = setup.log && setup.log.length > 0;
    const hasAccessToConvertedSetup = getBusinessRoleList().includes(this.props.case_details.role);
    const releaseStatus = [CaseStatus.ProviderEdit, CaseStatus.SetupReleaseReady];

    if (hasSetupLog) {
      const latestLogStatus = this.getLatestSetupStatus(setup);
      const isReadyForRelease = releaseStatus.includes(latestLogStatus);
      const isApprovalStatus = latestLogStatus === 'STATUS_DOCTOR_APPROVAL';
      const isProviderEdit = latestLogStatus === CaseStatus.ProviderEdit;
      const isInternal = setup.setup_status.includes('Internally');

      return (hasAccessToConvertedSetup && isReadyForRelease && !isInternal) || isApprovalStatus || isProviderEdit;
    }
    return false;
  }

  getSetupStatus(setup_process, case_detail) {
    const { manufacturing_process } = case_detail;
    const is_case_approved = manufacturing_process?.log?.length > 0;
    const case_status_code = case_detail.status_code;
    const is_post_approval = isPostApprovalStage(case_status_code, false);
    const lastest_setup_status_code = this.getLatestSetupStatus(setup_process[setup_process.length - 1]);

    if (is_case_approved || is_post_approval) return 'Approved';
    if (case_status_code === 'STATUS_HOLD') return 'On Hold';
    if (case_status_code === 'STATUS_CANCEL') return 'Case Cancelled';
    if (lastest_setup_status_code === 'Setup Ready for Release') return 'Uploaded';
    if (case_status_code === 'Doctor Provide Clarification') return 'Pending Doctor Clarification';
    if (lastest_setup_status_code !== 'STATUS_DOCTOR_APPROVAL') return 'Revision Request Submitted';

    return 'Review Smile Design';
  }

  getInbraceComment(setupLog) {
    const latestLog = setupLog.at(-1);
    if (!latestLog.text) {
      return {};
    }
    return {
      comment: latestLog.text,
      comment_date: latestLog.date,
      name: 'InBrace',
    };
  }

  getDoctorComment(setupStatus, nextSetup) {
    const hasValidLogText = nextSetup?.log?.[0]?.text;
    const isInternallyRejected = setupStatus === 'Internally Rejected';

    if (hasValidLogText && !isInternallyRejected) {
      return {
        comment: nextSetup.log[0].text,
        comment_date: nextSetup.log[0].date,
        name: this.getDoctorName(nextSetup),
      };
    }
    return {};
  }

  getDoctorName(nextSetup) {
    const inbrace_name = 'InBrace';
    const has_valid_log_name =
      nextSetup.log && nextSetup.log.length > 0 && nextSetup.log[0].text_created_by_first_name && nextSetup.log[0].text_created_by_last_name;

    if (has_valid_log_name) {
      const first_name = nextSetup.log[0].text_created_by_first_name;
      const last_name = nextSetup.log[0].text_created_by_last_name;
      const role = nextSetup.log[0].role;

      const doctor_name = `Dr. ${last_name}`;
      const dso_name = `${first_name} ${last_name}`;

      if (role === 'Business') return inbrace_name;
      if (role === 'DSO') return dso_name;
      return doctor_name;
    }

    return inbrace_name;
  }

  getLatestSetupStatus(setup) {
    return setup.log[setup.log.length - 1].status_code;
  }

  /**
   * Handle on retry save click
   * @function
   */
  onSavingRetryClick = () => {
    this.setState({ save_failed: false });
    setTimeout(this.retrySave, 2000);
  };

  /**
   * Retry save draft
   * @function
   */
  retrySave = () => {
    const retryBtn = document.getElementById('save_draft');
    if (retryBtn) retryBtn.click();
  };

  /**
   * Handle event when save draft failed
   * @function
   */
  onSaveFailed = () => {
    this.setState({ save_failed: true });
    this.setState((s) => ({ save_failed_count: s.save_failed_count + 1 }));
  };

  resetWasmState = async () => {
    const caseId = this.getCaseId();
    this.props.resetState();
    // FIXME: remove this dirty hack when API and architecture gets normal, and when request of case_details will take smaller time
    this.props.clearDraftSaves(caseId);
    this.setState({ reset_modal: false });
    resetCurrentTreatmentPlan();
    wasmClearHistory();
    this.saveDraft(true);
  };

  getSmileDesignTitle() {
    const { history_list } = this.state;
    if (history_list.length > 0) {
      return history_list[0].title;
    }
    return '';
  }

  /**
   * Check if doctor has access to Wasm setup
   * @function
   * @return {Boolean} True if doctor is enrolled in the program
   */
  doctorHasAccess() {
    const enrolled_programs = this.props?.case_details?.doctor?.program_enrollment;
    const hasAccess = enrolled_programs?.filter((p) => p.program_id === 'provider_edit').length !== 0;

    return hasAccess;
  }

  /**
   * Checks if case has wasm viewer enabled
   * @function
   * @return {boolean} True or false
   */
  isWasmViewerEnabled = () => {
    if (this.props.case_details) {
      const selectedCase = this.props.case_details.cases.find((detail) => detail.case_id === this.props.case_details.case_id);
      return selectedCase ? selectedCase.is_wasm_viewer_enabled : false;
    }
    return true;
  };

  /**
   * Checks if case has setup logs
   * @function
   * @return {boolean} True or false
   */
  hasSetupLog = () => {
    if (!_.isEmpty(this.props.case_file_list)) {
      const caseFile = this.props.case_file_list[this.props.case_file_list.case_id];
      return caseFile.setup_process && caseFile.setup_process.length > 0;
    }
    return false;
  };
  /**
   * Checks if the doctor is enrolled in a specific program.
   *
   * @param {string} program - The program to check enrollment for.
   * @returns {boolean} Returns true if enrolled; otherwise, false.
   */
  isEnrolled(program) {
    return this.props.case_details?.doctor?.program_enrollment?.includes(program);
  }
  /**
   * Filters an array of hint objects, each representing a UI hint, to return an array containing only the hints with the lowest phase number for each unique element selector.
   *
   * @param {Array<Object>} hints - The array of hint objects to filter.
   * @param {string} hints[].element - The CSS selector representing the UI element the hint is associated with.
   * @param {number} hints[].phase - The phase number of the hint, used to determine priority with lower numbers being higher priority.
   *
   * @returns {Array<Object>} A filtered array of hint objects.
   */
  filterHintsByLowestPhase(hints) {
    const elementHintMap = {};

    for (let i = 0; i < hints.length; i++) {
      const hint = hints[i];
      const storedHint = elementHintMap[hint.element];

      if (!storedHint || storedHint.phase > hint.phase) {
        elementHintMap[hint.element] = hint;
      }
    }

    return Object.values(elementHintMap);
  }
  /**
   * Calculates the hints based on certain conditions such as tour phase, revision status, and other contextual factors.
   * @returns {Array<Object>} An array of hints.
   */
  calculateHints = () => {
    const { isRevising, tmt, isEnrolledPhase2 } = this.props;
    const { tourPhaseCompleted, isIncMovementOpen, status } = this.state;
    let hints = [];

    if (tourPhaseCompleted === 0) {
      hints = isRevising ? _.concat(FIRST_HINTS, SECOND_HINTS) : FIRST_HINTS;

      if (isRevising) {
        if (tmt) hints.push(...EXTRA_HINTS);

        if (isIncMovementOpen) {
          hints.push(...INCREMENTAL_MOVEMENT_HINTS);
        } else {
          hints = hints.filter((hint) => !INCREMENTAL_MOVEMENT_HINTS.includes(hint));
        }
      }
      if (isEnrolledPhase2) {
        hints = this.filterHintsByLowestPhase(hints);
      } else {
        hints = hints.filter((hint) => hint.phase === 1);
      }
    } else if (tourPhaseCompleted === 1 && isEnrolledPhase2) {
      hints = _.concat(FIRST_HINTS, SECOND_HINTS).filter((hint) => hint.phase === 2);

      if (isIncMovementOpen) {
        hints.push(...INCREMENTAL_MOVEMENT_HINTS);
      }
    }
    if (isRevising) {
      hints = hints.filter((hint) => hint.element !== '.revise-btn');
    }
    if (status === 'Approved') {
      hints = hints.filter((hint) => hint.element !== '.revise-btn');
    }
    return hints;
  };
  /**
   * Handles popped hints, making adjustments to the hints array based on the popped hints state.
   * @param {Array<Object>} hints - The current array of hints.
   * @param {boolean} resetPoppedHints - Whether to reset the popped hints state.
   * @returns {Array<Object>} - The modified array of hints.
   */
  handlePoppedHints = (hints, resetPoppedHints) => {
    const { poppedHints } = this.state;
    if (resetPoppedHints && poppedHints.length > 0) {
      hints = hints.concat(poppedHints);
      this.setState({ poppedHints: [] });
    }
    return hints;
  };
  /**
   * Handles hints when WASM is animating, making adjustments to the hints array based on the isAnimating state.
   * Here we update the state right away instead of returning a new array of hints.
   * That is so that the hints are updated immediately, and not after 250 ms (the timeout duration @ componentDidUpdate).
   * This is called inside componentDidUpdate, and this calls componentDidUpdate by updating the state.
   * In theory, this should not cause infinite loop, as the state is only updated if the isAnimating state changes.
   *
   * @param {Boolean} isAnimating
   */
  handleAnimatingHints = (isAnimating) => {
    const { hints, poppedHints } = this.state;
    const stateChanges = {};
    if (isAnimating) {
      const initialStageHint = hints.find((hint) => hint.element === '.wasm-view-initial-stage');
      if (initialStageHint) {
        stateChanges.poppedHints = [...poppedHints, initialStageHint];
        stateChanges.hints = hints.filter((hint) => hint.element !== '.wasm-view-initial-stage');
      }
    } else {
      const initialStageHint = poppedHints.find((hint) => hint.element === '.wasm-view-initial-stage');
      if (initialStageHint) {
        stateChanges.hints = [...hints, initialStageHint];
        stateChanges.poppedHints = poppedHints.filter((hint) => hint.element !== '.wasm-view-initial-stage');
      }
    }
    if (Object.keys(stateChanges).length) {
      this.setState(stateChanges);
    }
  };
  /**
   * Modifies the hints array by removing a specific hint related to the revise action, if specified.
   * @param {Array<Object>} hints - The current array of hints.
   * @param {boolean} removeReviseHint - Whether to remove the revise hint.
   * @returns {Array<Object>} - The modified array of hints.
   */
  handleRemoveReviseHint = (hints, removeReviseHint) => {
    if (removeReviseHint) {
      const poppedHint = hints.find((hint) => hint.element === '.wasm-bottom-container-title');
      if (poppedHint) {
        hints = hints.filter((hint) => hint.element !== '.revise-btn');
        this.setState({ poppedHints: [poppedHint] });
      }
    }
    return hints;
  };
  /**
   * Filters out dismissed hints if the resetHints flag is not set.
   * @param {Array<Object>} hints - The current array of hints.
   * @param {boolean} resetHints - Whether to reset the dismissed hints state.
   * @returns {Array<Object>} - The filtered array of hints.
   */
  filterDismissedHints = (hints, resetHints) => {
    const { dismissedHints } = this.state;
    return resetHints ? hints : hints.filter((hint) => !dismissedHints.includes(hint));
  };
  /**
   * Retrieves hints based on the current state and props.
   * @function
   * @param {Boolean} resetHints - Whether to ignore dismissed hints.
   * @param {Boolean} resetPoppedHints - Whether to reset the popped hints state.
   * @param {Boolean} removeReviseHint - Whether to remove the revise hint.
   * @returns {Array<Object>} An array of hints.
   */
  getHints = (resetHints = false, resetPoppedHints = false, removeReviseHint = false) => {
    let hints = this.calculateHints();
    hints = this.handlePoppedHints(hints, resetPoppedHints);
    hints = this.handleRemoveReviseHint(hints, removeReviseHint);
    hints = this.filterDismissedHints(hints, resetHints);
    hints = removeDuplicatesFromArray(hints, 'element');
    return hints;
  };

  onExitSteps = () => {
    const { isEnrolledPhase2 } = this.props;
    const phaseCompleted = isEnrolledPhase2 ? 2 : 1;
    this.setState({ stepsEnabled: false });
    this.props.updateProductTour('wasm_viewer', true, phaseCompleted);
  };
  /**
   * Handle event when user clicks on the toggle collapse on the sidebar
   * @param {Boolean} collapse
   */
  onToggleCollapse = (collapse) => {
    const { hints, poppedHints } = this.state;

    if (collapse) {
      if (poppedHints.length > 0) {
        const newHints = _.concat(hints, poppedHints);
        setTimeout(() => this.setState({ hints: newHints, poppedHints: [] }), 200);
      }
    } else {
      const newHints = hints.filter((hint) => hint.element !== '.revise-btn' && hint.element !== '.photo-icon');

      if (newHints.length < hints.length) {
        this.setState({ hints: newHints, poppedHints: hints.filter((hint) => !newHints.includes(hint)) });
      }
    }
  };

  /**
   * Update hints and poppedHints based on certain conditions.
   *
   * @returns {void}
   */
  popSidebarHints = () => {
    const { hints, poppedHints } = this.state;
    const reviseHint = hints.find((hint) => hint.element === '.revise-btn');
    const confirmHint = hints.find((hint) => hint.element === '.wasm-sidebar-approval-buttons .button--danger');
    if (reviseHint || confirmHint) {
      const filteredHints = hints.filter((hint) => hint.element !== '.revise-btn' && hint.element !== '.wasm-sidebar-approval-buttons .button--danger');
      const newPoppedHints = _.uniqBy([...(reviseHint ? [reviseHint] : []), ...(confirmHint ? [confirmHint] : []), ...poppedHints], 'element');
      this.setState({ hints: filteredHints, poppedHints: newPoppedHints });
    }
  };
  /**
   * Handle event when user clicks on the history icon on the sidebar
   */
  onClickHistoryButton = () => {
    const { poppedHints, hints } = this.state;
    if (poppedHints.length > 0) {
      let newHints = _.concat(hints, poppedHints);
      newHints = removeDuplicatesFromArray(newHints, 'element');
      this.setState({ hints: newHints, poppedHints: [] });
    } else {
      this.setState({ hints: this.getHints() });
    }
  };
  /**
   * Handle event when a hint is dismissed or when the user toggles off the hints
   * @param {Number} i - index of the hint
   */
  onCloseHint = (i) => {
    const newHints = this.state.hints.filter((hint, index) => index !== i);
    const dismissedHints = _.concat(this.state.dismissedHints, this.state.hints[i]);
    const newState = { hints: newHints, dismissedHints };
    this.setState(newState);
  };
  /**
   * Handle event when user clicks on the "Turn on/off hints" button
   * @function
   */
  onToggleHints = () => {
    const { hints, hintsEnabled } = this.state;
    let newState = { hintsEnabled: false, hints: this.getHints() };
    if (!hintsEnabled || hints.length === 0) {
      newState.hintsEnabled = true;
      if (hints.length === 0) {
        newState.poppedHints = [];
        newState.dismissedHints = [];
        newState.hints = this.getHints(true, true);
      }
    }
    this.setState(newState);
  };
  /**
   * Get options for the tour depending on the current tour phase.
   * @returns {Object} - The options for the tour steps.
   */
  getTourStepsOptions = () => {
    const { tourPhaseCompleted } = this.state;
    let options = {
      showBullets: true,
      doneLabel: 'Done',
      exitOnOverlayClick: false,
    };
    if (tourPhaseCompleted > 0) {
      options = {
        showBullets: false,
        doneLabel: 'Explore Hints',
      };
    }
    return options;
  };

  render() {
    const {
      loading,
      error,
      selectedTreatmentPlanError,
      isRevising,
      tmt,
      case_details: caseDetails,
      case_details_loading: caseDetailsLoading,
      case_details_error: caseDetailsError,
      case_file_list: caseFileList,
      case_file_list_loading: caseFileListLoading,
      case_file_error: caseFileError,
      onToggleOption,
      isAnimating,
      currentTreatmentStage,
      sidebarWidth,
    } = this.props;
    const {
      collapse,
      status,
      percentage,
      toothPopupMenuState,
      wasNotFound,
      history_list: historyList,
      reset_modal: resetModal,
      stepsEnabled,
      hintsEnabled,
      hints,
      loadingInitialTreatmentPlan,
    } = this.state;
    const hasError = caseDetailsError || !caseDetails || !caseFileList || caseFileError;
    const display_loading = (!caseDetails && caseDetailsLoading) || (_.isEmpty(caseFileList) && caseFileListLoading);
    const isWasmViewerEnabled = this.isWasmViewerEnabled();
    const hasSetupLog = this.hasSetupLog();
    const isInitialStageSelected = currentTreatmentStage === ETreatmentPlanStage.Malocclusion;

    if (wasNotFound) {
      return <NotFound />;
    }

    if (display_loading) {
      return (
        <div className="background--dark wasm-root">
          <div className="abs-center">
            <CircleLoader />
          </div>
        </div>
      );
    }
    if ((hasError && caseDetailsError) || !isWasmViewerEnabled || !hasSetupLog) {
      return <NotFound />;
    }

    const tmtContainerStyles = {
      marginLeft: sidebarWidth,
      width: `calc(100% - ${sidebarWidth}px)`,
    };
    const wasmContainerStyles = {
      ...tmtContainerStyles,
      height: `calc(100% - ${this.state.tmtHeight}px)`,
    };
    const animationContainerStyles = {
      bottom: this.state.tmtHeight,
    };

    return (
      <div
        className={cn('background--dark wasm-root', { collapse, backgroundColor: WASM_BG })}
        onContextMenu={this.handleResetToothPopupMenu}
        onClick={this.handleResetToothPopupMenu}
      >
        <HideContentIf condition={!!selectedTreatmentPlanError || !!error}>
          <WasmShortcuts />
        </HideContentIf>
        {/* do not remove onChangeClientState*/}
        {/* the WASM build adds another title and it makes sure the correct title gets reapplied */}
        <Helmet onChangeClientState={() => {}}>
          <title>InBrace Smile Design™ Viewer</title>
        </Helmet>
        <div className="wasm-topbar">
          <div className="wasm-logo-container">
            <Logo onClick={this.onClickLogo} />
          </div>
          <HideContentIf condition={!!selectedTreatmentPlanError || !!error || !!loading}>
            <WasmToolBar
              case_id={this.getURLParams()}
              case_details={this.props.case_details}
              setSavingFailed={this.onSaveFailed}
              onClickReset={() => this.setState({ reset_modal: true, hintsEnabled: false })}
              onClickBoltonButton={this.popSidebarHints}
            />
          </HideContentIf>
        </div>
        <div className="wasm-content">
          <ResizableSidebar
            history_list={historyList}
            status={status}
            collapseWidth={150}
            onOpenModal={() => this.setState({ hintsEnabled: false })}
            onToggleCollapse={this.onToggleCollapse}
            onClickImageButton={this.popSidebarHints}
            onClickHistoryButton={this.onClickHistoryButton}
            onClickBoltonButton={this.popSidebarHints}
          />
          <WasmMain
            containerStyles={wasmContainerStyles}
            loadingInitialTreatmentPlan={loadingInitialTreatmentPlan}
            error={error}
            selectedTreatmentPlanError={selectedTreatmentPlanError}
            onClickRetry={() => {
              this.loadWasmCase(true);
            }}
            percentage={percentage}
            toothPopupMenuState={toothPopupMenuState}
            onClickToothPopupItem={this.handleClickToothPopupItem}
          />
          <Steps enabled={stepsEnabled} steps={this.getTourSteps()} options={this.getTourStepsOptions()} initialStep={0} onExit={this.onExitSteps} />
          <HideContentIf condition={!!selectedTreatmentPlanError || !!error || !!loading}>
            <Hints enabled={hintsEnabled} hints={hints} onClose={this.onCloseHint} />
          </HideContentIf>
          <WasmIncMovement
            onToggle={(incMovementOpen) => {
              this.setState({ isIncMovementOpen: incMovementOpen }, () => {
                this.setState({ hints: this.getHints() });
              });
            }}
          />
          <div className="wasm-bottom-overlay">
            {isAnimating && <WasmAnimationProgressBar isShowAnimationDisclaimer={!tmt} animationContainerStyles={animationContainerStyles} />}
            <HideContentIf condition={!tmt}>
              <WasmBottomContainer containerStyles={tmtContainerStyles} title="Tooth Movement Table" onClose={() => onToggleOption('tmt')}>
                <WasmMovementTable viewOnly={!isRevising || isInitialStageSelected} />
              </WasmBottomContainer>
            </HideContentIf>
          </div>
        </div>
        <ResetModal
          smile_design_name={this.getSmileDesignTitle()}
          show={resetModal}
          confirm={this.resetWasmState}
          close={() => this.setState({ reset_modal: false })}
        />
        <SaveDraftModal case_id={this.getURLParams()} onReturn2Portal={this.onClickLogo} />
        <SaveRevisionModal onReturn2Portal={this.onClickLogo} />
        <FeedbackForm enableDoNotShowAgain={false} autoCloseAfterSubmit={true} />
        <TourHelpPanel
          restartTour={() => this.setState({ stepsEnabled: true, tourPhaseCompleted: 0 })}
          toggleHints={() => this.onToggleHints()}
          hintsEnabled={hintsEnabled && hints.length > 0}
          toggleTourAndHints={() =>
            this.setState({ stepsEnabled: true, hintsEnabled: true, poppedHints: [], dismissedHints: [], tourPhaseCompleted: 0 }, () => {
              this.setState({ hints: this.getHints(true, true) });
            })
          }
        />
      </div>
    );
  }
}

WasmViewer.propTypes = {
  onFetchIPRAndTMTChanges: PropTypes.func.isRequired,
  updateIprState: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const wasm_state = getWasmViewerState(state);
  return {
    case_details: getCaseDetails(state),
    caseStatus: getCaseStatus(state),
    case_details_loading: getCaseDetailsLoading(state),
    case_details_error: getCaseDetailsError(state),
    case_file_list: getCaseFileList(state),
    case_file_list_loading: getCaseFileListLoading(state),
    case_file_error: getCaseFileError(state),
    isEnrolledPhase2: isEnrolledProviderEditPhase2(state),
    loading: wasm_state.loading,
    error: wasm_state.error,
    selectedTreatmentPlanError: wasm_state.selectedTreatmentPlanError,
    isLoadCaseRetry: wasm_state.isLoadCaseRetry,
    isRevising: wasm_state.is_revising,
    movement_table: wasm_state.movement_table,
    save_draft_loading: wasm_state.save_draft_loading,
    tmt: wasm_state.tmt,
    isAnimating: wasm_state.animation,
    editingModeState: wasm_state.editingModeState,
    revisionNote: wasm_state.revisionNote,
    currentTreatmentStage: wasm_state.step,
    product_tours: getProductTours(state),
    sidebarWidth: wasm_state.sidebar.width,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setLoading: setLoading,
      fetchCaseDetails: fetchCaseDetails,
      fetchCaseFileData: fetchCaseFileData,
      fetchProductTour: fetchProductTour,
      updateProductTour: updateProductTour,
      updateHistoryState: onUpdateHistory,
      updateMovementTable: updateMovementTable,
      updateInitialMovementTable: updateInitialMovementTable,
      updateInitialIPR: updateInitialIPR,
      updateIprTable: updateIprTable,
      updateEditingMode: updateEditingMode,
      resetState: resetState,
      setTreatmentPlanList: setTreatmentPlanList,
      setMissingTeeth: setMissingTeeth,
      setActiveTeeth: setActiveTeeth,
      setTMTIncrementDelta: setTMTIncrementDelta,
      onToggleOption: onToggleOption,
      setIsResetEnabled: setIsResetEnabled,
      setReviseOnCaseDetailsSuccess: setReviseOnCaseDetailsSuccess,
      autoSaveCaseDraft: autoSaveCaseDraft,
      setSelectedPlanError: setSelectedPlanError,
      setRevisedTreatmentPlan: setRevisedTreatmentPlan,
      clearDraftSaves: clearDraftSaves,
      updateIprState: updateIprState,
    },
    dispatch
  );

export default withIprAndTmtChanges(withUserPermission(connect(mapStateToProps, mapDispatchToProps)(WasmViewer)));
